<template>
  <!-- 预警记录 -->
  <div class="statistics">
    <div class="seizeSeat"></div>
    <div class="statistics-main">
      <div v-if="dataList && dataList.length !== 0">
        <div
          class="statistics-item"
          @click="itemClick(item)"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div class="statistics-top">
            <div class="statistics-name">
              <div>{{ item.targetName }}</div>
              <div>{{ item.phone }}</div>
            </div>
            <div class="statistics-call">
              <div>{{ item.warnTypeMark }}</div>
              <div :class="item.state == 2 ? 'greenStyle' : 'redStyle'"></div>
            </div>
          </div>
          <div class="statistics-center">
            {{ item.warnAddress }}
          </div>
          <div class="statistics-time">{{ item.warnTime }}</div>
        </div>
      </div>
      <div class="noData" v-else>
        暂无数据
      </div>
    </div>
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :pager-count="3"
        background=""
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <div>
      <elderlyWarningDetails ref="elderlyWarningDetails" />
      <warningDetails ref="warningDetails" />
    </div>
  </div>
</template>
<script>
import { workOrderListData } from '@/api/warningAndMap/index.js'
import elderlyWarningDetails from './elderlyWarningDetails.vue'
import warningDetails from './warningDetails.vue'
export default {
  components: {
    elderlyWarningDetails,
    warningDetails,
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 5,
      total: 0,
      dataList: [],
    }
  },
  computed: {
    insNo() {
      return this.$route.query.insNo
    },
  },
  mounted() {
    window.workOrderListData = this.workOrderListData
    this.workOrderListData()
  },
  methods: {
    itemClick(data) {
      if (data.bindType == 1) {
        this.$refs.elderlyWarningDetails.openBox(data)
      } else {
        this.$refs.warningDetails.openBox(data)
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.workOrderListData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.workOrderListData()
    },
    workOrderListData() {
      let obj = {
        currPage: this.currentPage,
        pageSize: this.pageSize,
      }
      if (this.insNo) {
        obj['institutionNo'] = this.insNo
      }
      workOrderListData(obj).then((res) => {
        if (res.code == 0) {
          this.total = res.data.totalCount
          this.dataList = res.data.data
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.statistics {
  width: 100%;
  height: 21.28rem;
  background: url('../../static/jilu.png') 0 0 no-repeat;
  background-size: 100% 100%;
  .seizeSeat {
    height: 1.5rem;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #0b529e;
    border-radius: 32px;
  }

  ::-webkit-scrollbar-track {
    background-color: #122342;
    border-radius: 32px;
  }
  .statistics-main {
    margin-right: 0.3rem;
    margin-top: 0.6rem;
    margin-left: 0.6rem;
    padding-right: 0.3rem;
    height: 16.7rem;
    overflow-y: auto;
    .noData {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.3rem;
      color: #00d5ff;
    }
    .statistics-item {
      background: #050d26;
      border-radius: 2px;
      border: 1px solid #213060;
      padding: 0.5rem;
      margin-bottom: 0.3rem;
      cursor: pointer;
      .statistics-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.3rem;
        .statistics-name {
          display: flex;
          align-items: center;
          & > div:first-child {
            margin-right: 0.5rem;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 20px;
          }
          & > div:last-child {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 20px;
          }
        }
        .statistics-call {
          display: flex;
          align-items: center;
          & > div:first-child {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #fe852a;
            line-height: 20px;
            margin-right: 0.5rem;
          }
          .greenStyle {
            width: 8px;
            height: 8px;
            background: #52c41a;
            border-radius: 8px;
          }
          .redStyle {
            width: 8px;
            height: 8px;
            background: #ff4d4f;
            border-radius: 8px;
          }
        }
      }
      .statistics-center {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8eb8e6;
        line-height: 16px;
      }
      .statistics-time {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8eb8e6;
        line-height: 16px;
        text-align: right;
      }
    }
  }
  .page {
    padding: 0.6rem 0.6rem 0 0.6rem;
    text-align: right;
    ::v-deep .el-pagination.is-background .btn-next,
    ::v-deep .el-pagination.is-background .btn-prev,
    ::v-deep .el-pagination.is-background .el-pager li {
      background-color: #0b529e;
      color: #ffffff;
    }
    ::v-deep .el-pager li.active {
      background-color: #ed7300 !important;
      color: #ffffff;
    }
  }
}
</style>
