<template>
  <!-- 老人预警详情 -->
  <div class="elderlyWarningDetails">
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%"
      :show-close="true"
    >
      <div class="details-main" v-if="workDetailData">
        <div class="title-name">
          <div class="titles">预警详情</div>
          <div class="title-img" @click="closeBox">
            <img src="@/components/warningCenter/static/cha.png" alt="" />
          </div>
        </div>
        <div class="details-mains">
          <div class="mains-b">
            <div class="mains-c">
              <div>{{ workDetailData.targetName }}</div>
            </div>
          </div>
          <div class="mains-e">
            <div>
              {{ workDetailData.deviceName }}/爱牵挂/{{
                workDetailData.deviceType
              }}
            </div>
          </div>
          <div class="ds-cen-ter">
            <div>
              <div class="mains-f">
                <div class="mains-g">
                  <div>
                    <img
                      src="@/components/warningCenter/static/time.png"
                      alt=""
                    />
                  </div>
                  <div>{{ workDetailData.warnTime }}</div>
                </div>
                <div class="mains-g">
                  <div>
                    <img src="@/components/warningCenter/static/address.png" />
                  </div>
                  <div>{{ workDetailData.warnAddress }}</div>
                </div>
              </div>
              <div class="mains-h">
                <div class="mains-i">
                  <img
                    src="@/components/warningCenter/static/yujin.png"
                    alt=""
                  />
                </div>
                <div class="mains-j">
                  {{ workDetailData.warnCategoryMark }}：
                </div>
                <div class="mains-k">{{ workDetailData.warnTypeMark }}</div>
              </div>
            </div>
            <div>
              <div class="ds-cen-yujing">
                <span>预警次数：</span>
                <span>{{workDetailData.deviceAlarms.length}}次</span>
              </div>
              <div class="ds-cen-cols">
                <div
                  class="ds-cen-list"
                  v-for="(item, index) in workDetailData.deviceAlarms"
                  :key="index"
                >
                  {{ item.time }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="workDetailData.state == 2">
            <div class="mains-a">处理结果</div>
            <div class="processing-results">
              <div>服务时间：{{ workDetailData.dealTime }}</div>
              <div>服务人员：{{ workDetailData.staffName }}</div>
            </div>
            <div class="processing-a">
              <div>处理结果：</div>
              <div>
                {{ workDetailData.dealResult }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { workDetail } from '@/api/warningAndMap/index.js'
export default {
  data() {
    return {
      dialogVisible: false,
      workData: null, // 工单数据
      workDetailData: null, // 工单详情
    }
  },
  methods: {
    openBox(data) {
      this.workData = data
      this.workDetail()
    },
    closeBox() {
      this.workData = null // 工单数据
      this.workDetailData = null // 工单详情
      this.dialogVisible = false
    },
    workDetail() {
      workDetail(this.workData.id).then((res) => {
        if (res.code == 0) {
          this.workDetailData = res.data
          this.dialogVisible = true
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.elderlyWarningDetails {
  ::v-deep .el-dialog__header {
    display: none;
  }
  ::v-deep .el-dialog__body {
    background: rgba(4, 9, 41, 0.95);
    box-shadow: inset 0px 0px 24px 0px rgba(0, 122, 255, 0.81);
    border-radius: 2px;
    border: 1px solid #0c87ff;
    padding: 0;
  }
  .details-main {
    .title-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.6rem 0.8rem;
      border-bottom: 1px solid #242b5f;
      .titles {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 22px;
      }
      .title-img {
        width: 16px;
        height: 16px;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .details-mains {
      padding: 0.4rem 0.8rem 0.8rem 0.8rem;
      .mains-a {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #8eb8e6;
        line-height: 22px;
        margin-bottom: 0.7rem;
      }
      .mains-b {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 0.2rem;
        .mains-c {
          display: flex;
          align-items: center;
          justify-content: center;
          & > div:first-child {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #8eb8e6;
            line-height: 22px;
            margin-right: 0.2rem;
          }
          & > div:last-child {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #8eb8e6;
            line-height: 16px;
          }
        }
      }
      .mains-e {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        div {
          background: #131940;
          border-radius: 2px;
          padding: 0 0.1rem;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #7c86d0;
          line-height: 22px;
        }
      }
      .mains-f {
        display: flex;
        flex-direction: column;
        .mains-g {
          display: flex;
          align-items: flex-start;
          margin-bottom: 0.5rem;
          flex: 1;
          & > div:first-child {
            width: 14px;
            height: 14px;
            margin-right: 0.2rem;
            img {
              width: 14px;
              height: 14px;
            }
          }
          & > div:last-child {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #8eb8e6;
            line-height: 16px;
          }
        }
      }
      .mains-h {
        display: flex;
        align-items: center;
        margin-bottom: 0.7rem;
        .mains-i {
          width: 14px;
          height: 14px;
          margin-right: 0.3rem;
          img {
            width: 14px;
            height: 14px;
          }
        }
        .mains-j {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8eb8e6;
          line-height: 16px;
        }
        .mains-k {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff4d4f;
          line-height: 16px;
        }
      }
      .processing-results {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        & > div {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8eb8e6;
          line-height: 16px;
          flex: 1;
        }
      }
      .processing-a {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8eb8e6;
        line-height: 16px;
        margin-bottom: 0.5rem;
      }
    }
  }
}
.ds-cen-ter {
  display: flex;
  justify-content: space-between;
}
.ds-cen-yujing {
  display: flex;
  align-items: center;
  width: 5.875rem;
  text-align: left;
  > span:nth-of-type(1) {
    font-size: 14px;
    font-weight: 400;
    color: #8eb8e6;
  }
  > span:nth-of-type(2) {
    font-size: 14px;
    font-weight: 400;
    color: #ff4d4f;
  }
}
.ds-cen-list {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  margin-top: 0.333rem;
}
.ds-cen-cols {
  height: 3.3rem;
  overflow-y: auto;
}
.ds-cen-cols::-webkit-scrollbar {
  display: none;
}
</style>
