import request from '@/utils/request'

// 获取最新的工单列表
export function workOrderList(data) {
  return request({
    url: '/hp/platform/data/v1/screen/gov/job/tops',
    method: 'get',
    params: data,
    needTenantNo: true,
  })
}

// 查询工单详情
export function workDetail(data) {
  return request({
    url: `/hp/platform/data/v1/screen/gov/institution/detail/${data}`,
    needTenantNo: true,
  })
}

// 预警统计
export function elderlyEquipment(data) {
  return request({
    url: '/hp/platform/data/v1/screen/gov/job/warn/statistics',
    method: 'get',
    params: data,
    needTenantNo: true,
  })
}

// 长者总数，设备总数
export function totalStatistics(data) {
  return request({
    url: '/hp/platform/data/v1/screen/gov/job/statistics',
    method: 'get',
    params: data,
    needTenantNo: true,
  })
}

// 工单列表
export function workOrderListData(data) {
  return request({
    url: '/hp/platform/data/v1/screen/gov/job/page',
    method: 'post',
    data: data,
    needTenantNo: true,
  })
}
