<template>
  <!-- 预警统计 -->
  <div class="record">
    <div class="seizeSeat">
      <div class="dateTabs">
        <div
          v-for="(item, index) in tabsList"
          :key="index"
          :class="tabsActive == index ? 'tabItem' : ''"
          @click="clickTabsList(item, index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="record-main">
      <div class="statisticsData">
        <div class="statisticsData-num">
          <div>总数</div>
          <div>{{ total }}</div>
        </div>
        <div class="pending">
          <div>待处理预警</div>
          <div>{{ pending }}</div>
        </div>
        <div class="complete">
          <div>完成率</div>
          <div>{{ percent }}%</div>
        </div>
      </div>
      <div class="classification">
        <div class="cpopover-main" v-if="statisticsArr">
          <div class="main-one">
            <div class="home-security" @click="homeSecurity(statisticsArr[0])">
              <div class="home-name">居家安防</div>
            </div>
            <el-popover
              v-for="(item, index) in statisticsArr[0].typeStatistics"
              :key="index + 'ac'"
              :ref="'popover' + index + 'ac'"
              placement="top"
              :offset="-40"
              :visible-arrow="false"
              trigger="hover"
              popper-class="cpopover"
            >
              <div class="csPop">完成率：{{ item.percent }}%</div>
            </el-popover>
            <div
              class="main-item"
              v-for="(item, index) in statisticsArr[0].typeStatistics"
              :key="index + 'a'"
              v-popover="'popover' + index + 'ac'"
            >
              <div class="main-item-a">
                <div class="main-item-b">
                  <div class="main-item-name">
                    {{ item.subCategoryMarker }}
                  </div>
                  <div class="main-item-num">{{ item.total }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="main-two">
            <div class="home-security" @click="homeSecurity(statisticsArr[1])">
              <div class="home-name">防走失监护</div>
            </div>
            <el-popover
              v-for="(item1, index1) in statisticsArr[1].typeStatistics"
              :key="index1 + 'bc'"
              :ref="'popover' + index1 + 'bc'"
              placement="top"
              :offset="-40"
              :visible-arrow="false"
              trigger="hover"
              popper-class="cpopover"
            >
              <div class="csPop">完成率：{{ item1.percent }}%</div>
            </el-popover>
            <div
              class="main-item"
              v-for="(item1, index1) in statisticsArr[1].typeStatistics"
              :key="index1 + 'b'"
              v-popover="'popover' + index1 + 'bc'"
            >
              <div class="main-item-a">
                <div class="main-item-b">
                  <div class="main-item-name">
                    {{ item1.subCategoryMarker }}
                  </div>
                  <div class="main-item-num">{{ item1.total }}</div>
                </div>
              </div>
            </div>
            <div class="home-security" @click="homeSecurity(statisticsArr[2])">
              <div class="home-name">生命体征监测</div>
            </div>
            <el-popover
              v-for="(item2, index2) in statisticsArr[2].typeStatistics"
              :key="index2 + 'cc'"
              :ref="'popover' + index2 + 'cc'"
              placement="top"
              :offset="-40"
              :visible-arrow="false"
              trigger="hover"
              popper-class="cpopover"
            >
              <div class="csPop">完成率：{{ item2.percent }}%</div>
            </el-popover>
            <div
              class="main-item"
              v-for="(item2, index2) in statisticsArr[2].typeStatistics"
              :key="index2 + 'c'"
              v-popover="'popover' + index2 + 'cc'"
            >
              <div class="main-item-a">
                <div class="main-item-b">
                  <div class="main-item-name">
                    {{ item2.subCategoryMarker }}
                  </div>
                  <div class="main-item-num">{{ item2.total }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { elderlyEquipment } from '@/api/warningAndMap/index.js'
export default {
  data() {
    return {
      statisticsArr: null,
      total: 0,
      percent: 0,
      pending: 0,
      tabsActive: 0,
      tabsActiveValue: 'MONTH',
      tabsList: [
        {
          value: 'MONTH',
          name: '月',
        },
        {
          value: 'WEEK',
          name: '周',
        },
        {
          value: 'DAY',
          name: '日',
        },
      ],
    }
  },
  mounted() {
    window.getElderlyEquipment = this.getElderlyEquipment
    this.getElderlyEquipment()
  },
  computed: {
    insNo() {
      return this.$route.query.insNo
    },
  },
  methods: {
    getElderlyEquipment() {
      let obj = {
        timeType: this.tabsActiveValue,
      }
      if (this.insNo) {
        obj['institutionNo'] = this.insNo
      }
      elderlyEquipment(obj).then((res) => {
        if (res.code == 0) {
          this.total = res.data.total ? res.data.total : 0
          this.percent = res.data.percent ? res.data.percent : 0
          this.pending = res.data.pending ? res.data.pending : 0
          this.getStatisticsArr(res.data.categoryStatistics)
        }
      })
    },
    clickTabsList(data, index) {
      this.tabsActive = index
      this.tabsActiveValue = data.value
      this.getElderlyEquipment()
    },
    getStatisticsArr(data) {
      let obj = [
        {
          category: 'HOME_SECURITY',
          categoryMarker: '居家安防',
          total: 0,
          typeStatistics: [
            {
              subCategory: 'SOS',
              subCategoryMarker: 'SOS报警：',
              total: 0,
              percent: 0,
            },
            {
              subCategory: 'FLOODED_ALARM',
              subCategoryMarker: '水浸报警：',
              total: 0,
              percent: 0,
            },
            {
              subCategory: 'SMOKE_ALARM',
              subCategoryMarker: '烟感报警：',
              total: 0,
              percent: 0,
            },
            {
              subCategory: 'DOOR_ALARM',
              subCategoryMarker: '门磁报警：',
              total: 0,
              percent: 0,
            },
            {
              subCategory: 'INFRARED_ALARM',
              subCategoryMarker: '红外探测报警：',
              total: 0,
              percent: 0,
            },
            {
              subCategory: 'GAS_ALARM',
              subCategoryMarker: '燃气泄漏报警：',
              total: 0,
              percent: 0,
            },
          ],
        },
        {
          category: 'PREVENT_LOSS',
          categoryMarker: '防走失监护',
          total: 0,
          typeStatistics: [
            {
              subCategory: 'SOS',
              subCategoryMarker: 'SOS报警：',
              total: 0,
              percent: 0,
            },
            {
              subCategory: 'FALL_ALARM',
              subCategoryMarker: '跌倒报警：',
              total: 0,
              percent: 0,
            },
            {
              subCategory: 'ABNORMAL_HEART_RATE_ALARM',
              subCategoryMarker: '心率异常报警：',
              total: 0,
              percent: 0,
            },
            {
              subCategory: 'ELECTRONIC_FENCE_ALARM',
              subCategoryMarker: '电子围栏报警：',
              total: 0,
              percent: 0,
            },
          ],
        },
        {
          category: 'LIFT_MONITOR',
          typeMarker: '生命体征监测',
          total: 0,
          typeStatistics: [
            {
              subCategory: 'ABNORMAL_BREATHING_ALARM',
              subCategoryMarker: '呼吸异常报警：',
              total: 0,
              percent: 0,
            },
            {
              subCategory: 'ABNORMAL_HEART_RATE_ALARM',
              subCategoryMarker: '心率异常报警：',
              total: 0,
              percent: 0,
            },
            {
              subCategory: 'LEAVING_BED_TOO_LONG_ALARM',
              subCategoryMarker: '离床过长报警：',
              total: 0,
              percent: 0,
            },
          ],
        },
      ]
      if (data && data.length !== 0) {
        data.forEach((item) => {
          // 居家安防
          if (item.category == 'HOME_SECURITY') {
            item.typeStatistics.forEach((ele) => {
              obj[0].typeStatistics.forEach((el) => {
                if (ele.subCategory == el.subCategory) {
                  el.total = ele.total
                  el.percent = ele.percent
                }
              })
            })
          }
          // 防走失监护
          if (item.category == 'PREVENT_LOSS') {
            item.typeStatistics.forEach((ele) => {
              obj[1].typeStatistics.forEach((el) => {
                if (ele.subCategory == el.subCategory) {
                  el.total = ele.total
                  el.percent = ele.percent
                }
              })
            })
          }
          // 生命体征监测
          if (item.category == 'LIFT_MONITOR') {
            item.typeStatistics.forEach((ele) => {
              obj[2].typeStatistics.forEach((el) => {
                if (ele.subCategory == el.subCategory) {
                  el.total = ele.total
                  el.percent = ele.percent
                }
              })
            })
          }
        })
      }
      this.statisticsArr = obj
    },
  },
}
</script>
<style lang="scss" scoped>
.record {
  width: 100%;
  height: 19rem;
  background: url('../../static/tongji.png') 0 0 no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0.5rem;
  .seizeSeat {
    height: 1.6rem;
    display: flex;
    justify-content: flex-end;
    padding-right: 0.5rem;
    .dateTabs {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8eb8e6;
      line-height: 17px;
      div {
        margin-right: 0.4rem;
        cursor: pointer;
      }
      & > div:last-child {
        margin-right: 0px;
      }
      .tabItem {
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ff8529;
        line-height: 17px;
      }
    }
  }
  .record-main {
    padding: 0.5rem;
    .statisticsData {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #050d26;
      border-radius: 2px;
      border: 1px solid #213060;
      padding: 0.5rem;
      .statisticsData-num {
        display: flex;
        flex-direction: column;
        justify-content: center;
        & > div:first-child {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8eb8e6;
          line-height: 17px;
        }
        & > div:last-child {
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #b4d3fe;
          line-height: 33px;
        }
      }
      .pending {
        display: flex;
        flex-direction: column;
        justify-content: center;
        & > div:first-child {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8eb8e6;
          line-height: 17px;
        }
        & > div:last-child {
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ff4d4f;
          line-height: 33px;
        }
      }
      .complete {
        display: flex;
        flex-direction: column;
        justify-content: center;
        & > div:first-child {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8eb8e6;
          line-height: 17px;
        }
        & > div:last-child {
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #52c41a;
          line-height: 33px;
        }
      }
    }
  }

  .classification {
    background: #050d26;
    border-radius: 2px;
    border: 1px solid #213060;
    margin-top: 0.6rem;

    .cpopover-main {
      display: flex;
      padding: 16px 16px 8px 16px;
      .main-one {
        padding-right: 16px;
        flex: 1;
      }
      .main-two {
        padding-left: 16px;
        flex: 1;
      }

      .home-security {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .home-name {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 20px;
        }
      }

      .main-item:hover {
        background: #152046;
      }

      .main-item {
        cursor: pointer;
        height: 33px;
        display: flex;
        align-items: center;
        .main-item-a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .main-item-b {
            display: flex;
            align-items: center;
            .main-item-name {
              text-align: right;
              width: 100px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #8eb8e6;
              line-height: 20px;
            }
            .main-item-num {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ff4d4f;
              line-height: 20px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.cpopover {
  background: url('../../static/qipao.png') 0 0 no-repeat;
  background-size: 100% 100%;
  border: none;
  margin-bottom: 0px !important;
  padding-top: 6px;
  min-width: 115px;
  .csPop {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #52c41a;
    line-height: 20px;
  }
}
</style>
