<template>
  <!-- 预警中心 -->
  <div class="warningCenter">
    <div class="warningCenter-top">
      <div @click="clickBack"><img src="./static/back.png" alt="" /></div>
      <div @click="clickFullscreen">
        <img v-if="isFullscreen" src="./static/shou.png" alt="" />
        <img v-else src="./static/fang.png" alt="" />
      </div>
    </div>
    <div class="warning-main">
      <div class="warning-map">
        <positioningMap
          ref="positioningMap"
          :totalNum="customerTotal"
          :devTotal="deviceTotal"
          :region="pathData"
        />
      </div>
      <div class="warning-right">
        <record />
        <statistics />
      </div>
    </div>
  </div>
</template>
<script>
import positioningMap from '@/components/warningCenter/components/positioningMap/index.vue'
import record from './components/record/index.vue'
import statistics from './components/statistics/index.vue'
import screenfull from 'screenfull'
import { workOrderList, totalStatistics } from '@/api/warningAndMap/index.js'
export default {
  components: {
    positioningMap,
    record,
    statistics,
  },
  data() {
    return {
      isFullscreen: false,
      customerTotal: '0',
      deviceTotal: '0',
    }
  },
  computed: {
    pathData() {
      let obj = JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
      let name_obj = obj.province + obj.area + obj.city
      console.log('youmeiyuou', name_obj)
      return name_obj
    },
    insNo() {
      return this.$route.query.insNo
    },
  },
  mounted() {
    window.punctuationData = this.punctuationData
    window.totalStatistics = this.totalStatistics
    this.punctuationData()
    this.totalStatistics()
  },
  methods: {
    clickBack() {
      this.$router.go(-1)
    },
    clickFullscreen() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: '你的浏览器不支持全屏',
          type: 'warning',
        })
        return false
      }
      if (this.isFullscreen == false) {
        this.isFullscreen = true
        screenfull.toggle()
      } else {
        this.isFullscreen = false
        screenfull.toggle()
      }
    },
    totalStatistics() {
      let obj = {}
      if (this.insNo) {
        obj['institutionNo'] = this.insNo
      }
      totalStatistics(obj).then((res) => {
        this.customerTotal = res.data.customerTotal.toString()
        this.deviceTotal = res.data.deviceTotal.toString()
      })
    },
    punctuationData() {
      let obj = {
        offset: 10,
        state: 0,
      }
      if (this.insNo) {
        obj['institutionNo'] = this.insNo
      }
      workOrderList(obj).then((res) => {
        if (res.code == 0) {
          let arr = res.data && res.data.length !== 0 ? res.data : []
          this.$refs.positioningMap.initializeMap(arr)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.warningCenter {
  width: 100%;
  height: 45rem;
  background: linear-gradient(180deg, #03063b 0%, #10064a 100%);
  .warningCenter-top {
    height: 2.8rem;
    background: url('./static/topHeader.png') 0 0 no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
  }
  .warning-main {
    padding: 0.6rem 0.5rem;
    display: flex;
    .warning-map {
      flex: 1;
      padding: 0.5rem;
      background: #051137;
      box-shadow: inset 0px 0px 23px 0px rgba(58, 95, 255, 0.5);
      border-radius: 2px;
      opacity: 0.75;
      border: 1px solid rgba(80, 142, 255, 0.22);
      height: 39.8rem;
      margin-right: 0.5rem;
    }
    .warning-right {
      width: 17rem;
    }
  }
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #312316 !important;
  border: 1px solid #ed7301;
  color: #ed7301;
}
::v-deep .el-pagination__total {
  color: #8eb8e6;
}
</style>
