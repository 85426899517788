<template>
  <!-- 预警中心地图 -->
  <div class="positioningMap">
    <div id="posMap" class="posMap"></div>
    <div class="posMap-main">
      <div class="totalNum">
        <div>长者总数</div>
        <ScrollNumber :val="totalNum"></ScrollNumber>
      </div>
      <div class="devTotal">
        <div>设备总数</div>
        <ScrollNumber :val="devTotal"></ScrollNumber>
      </div>
    </div>
  </div>
</template>
<script>
import { loadBaiDuMap } from '@/utils/baiduUitls'
import bdMapConfig from '../../config/bdMap_config.js'
import ScrollNumber from '../digitalRollover/digitalRollover.vue'
export default {
  props: {
    // 区域中文地点
    region: {
      type: String,
      default: '',
    },
    // 长者总数
    totalNum: {
      type: String,
      default: '',
    },
    // 设备总数
    devTotal: {
      type: String,
      default: '',
    },
  },
  components: {
    ScrollNumber,
  },
  created () {
    bdMapConfig.thisFun(this)
  },
  methods: {
    bDmap: bdMapConfig.bDmap,
    initializeMap (listData) {
      loadBaiDuMap({ edition: '3.0' }).then((BMap) => {
        this.bDmap(BMap, listData, this.region)
      })
    },
  },
}
</script>
<style scoped lang="scss">
@import '../../style/positioningMap.scss';
::v-deep .BMap_bubble_center {
  width: 100% !important;
  height: 200px !important;

  .BMap_bubble_content {
    width: 100% !important;
    height: auto !important;
    background: url('../../static/kuang.png') 0 0 no-repeat;
    background-size: 100% 100%;
    padding: 16px 16px 20px 16px !important;
    overflow: visible !important;
  }
}

.posMap-main {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  color: #ff8529 !important;
  z-index: 88;
  .totalNum {
    padding: 0.3rem 0.3rem 0.2rem 0.3rem;
    background: url('../../static/laorenshu.png') 0 0 no-repeat;
    background-size: 100% 100%;
    margin-right: 0.6rem;
    div {
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 12px;
      margin-bottom: 0.2rem;
    }
  }

  .devTotal {
    padding: 0.3rem 0.3rem 0.2rem 0.3rem;
    background: url('../../static/laorenshu.png') 0 0 no-repeat;
    background-size: 100% 100%;
    div {
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 12px;
      margin-bottom: 0.2rem;
    }
  }
}
</style>
