let imgMan = require('../static/man.png')
let imgwuMan = require('../static/wuman.png')
let imgAddress = require('../static/addressCall.png')

let _this = null
let BMap = null
let map = null

function bDmap(BMaps, ele, regionData) {
  // 构造函数赋值
  BMap = BMaps
  // 创建Map实例
  map = new BMap.Map('posMap')
  // 框区域
  let region = regionData
  drawPolygon(region, ele)
  // 启用滚轮放大缩小
  map.enableScrollWheelZoom(true)
}

// 绘制行政区划轮廓
function drawPolygon (regionName, ele) {
  let bdary = new BMap.Boundary()
  bdary.get(regionName, function(rs) {
    //轮廓的总数 —— 有的区由多个封闭区域组成
    let count = rs.boundaries.length
    if (!count) {
      console.log('未能获取到当前输入的行政区域')
      return
    }
    // 循环绘制
    for (let i = 0; i < count; i++) {
      //创建多边形覆盖物
      let ply = new BMap.Polygon(rs.boundaries[i], {
        strokeWeight: 3,
        strokeColor: '#368EFF',
        strokeStyle: 'solid',
        fillOpacity: 0.15,
        fillColor: '#368EFF',
      })
      if (i == 0) {
        //调整视野
        map.setViewport(ply.getPath())
      }
      //添加覆盖物
      map.addOverlay(ply)
    }
    // 百度地图自定义样式
    map.setMapStyleV2({
      styleId: 'f3cd572fe93000d35eb96c8b04dc0c3a',
    })
    // 循环地图打点
    cycleManagement(ele)
  })
}

// 循环地图打点
function cycleManagement(ele) {
  ele.forEach((item, index) => {
    punctuationPopup(item, index)
  })
}

// 地图标记点
function punctuationPopup(item, index) {
  var point = new BMap.Point(item.warnLng, item.warnLat)
  // 创建点标记
  let imgIcon =
    item.bindType == 1
      ? item.gender == 'MALE'
        ? imgMan
        : imgwuMan
      : imgAddress
  var myIcon = new BMap.Icon(imgIcon, new BMap.Size(40, 43))
  myIcon.setImageSize(new BMap.Size(40, 43))
  var marker = new BMap.Marker(point, { icon: myIcon })
  map.addOverlay(marker)
  // 创建信息窗口
  var opts = {
    offset: new BMap.Size(-35, 35), // 设置文本偏移量
  }
  var infoWindow = new BMap.InfoWindow(textBox(item), opts)
  // 第一次需要将弹框弹出来
  if (index == 0) {
    setTimeout(() => {
      map.openInfoWindow(infoWindow, point) // 开启信息窗口
      // 添加点击事件(查看档案)
      if (item.bindType == 1) {
        viewProfile(infoWindow, item)
      } else {
        document.getElementById('toFiles').remove()
      }
      if (item.jobOrders.length == 1) {
        // 如果次数等于0，那么隐藏次数
        if (item.jobOrders[0].deviceAlarms.length == 0) {
          document.getElementById('num').remove()
        } else {
          // 添加点击事件(次数1)
          numberClicks(infoWindow, item.jobOrders[0])
        }
      } else if (item.jobOrders.length == 2) {
        if (item.jobOrders[0].deviceAlarms.length == 0) {
          document.getElementById('num').remove()
        } else {
          // 添加点击事件(次数1)
          numberClicks(infoWindow, item.jobOrders[0])
        }
        if (item.jobOrders[1].deviceAlarms.length == 0) {
          document.getElementById('nums').remove()
        } else {
          // 添加点击事件(次数2)
          numberClickss(infoWindow, item.jobOrders[1])
        }
      } else {
        if (item.jobOrders[0].deviceAlarms.length == 0) {
          document.getElementById('num').remove()
        } else {
          // 添加点击事件(次数1)
          numberClicks(infoWindow, item.jobOrders[0])
        }
        if (item.jobOrders[1].deviceAlarms.length == 0) {
          document.getElementById('nums').remove()
        } else {
          // 添加点击事件(次数2)
          numberClickss(infoWindow, item.jobOrders[1])
        }
        if (item.bindType == 1) {
          // 添加点击事件(查看全部)
          viewAll(infoWindow, item)
        } else {
          document.getElementById('checkAll-btn').remove()
        }
      }
    }, 700)
  }
  // 点标记添加点击事件
  marker.addEventListener('click', function() {
    map.openInfoWindow(infoWindow, point) // 开启信息窗口
    // 添加点击事件(查看档案)
    if (item.bindType == 1) {
      viewProfile(infoWindow, item)
    } else {
      document.getElementById('toFiles').remove()
    }
    if (item.jobOrders.length == 1) {
      // 如果次数等于0，那么隐藏次数
      if (item.jobOrders[0].deviceAlarms.length == 0) {
        document.getElementById('num').remove()
      } else {
        // 添加点击事件(次数1)
        numberClicks(infoWindow, item.jobOrders[0])
      }
    } else if (item.jobOrders.length == 2) {
      if (item.jobOrders[0].deviceAlarms.length == 0) {
        document.getElementById('num').remove()
      } else {
        // 添加点击事件(次数1)
        numberClicks(infoWindow, item.jobOrders[0])
      }
      if (item.jobOrders[1].deviceAlarms.length == 0) {
        document.getElementById('nums').remove()
      } else {
        // 添加点击事件(次数2)
        numberClickss(infoWindow, item.jobOrders[1])
      }
    } else {
      if (item.jobOrders[0].deviceAlarms.length == 0) {
        document.getElementById('num').remove()
      } else {
        // 添加点击事件(次数1)
        numberClicks(infoWindow, item.jobOrders[0])
      }
      if (item.jobOrders[1].deviceAlarms.length == 0) {
        document.getElementById('nums').remove()
      } else {
        // 添加点击事件(次数2)
        numberClickss(infoWindow, item.jobOrders[1])
      }

      if (item.bindType == 1) {
        // 添加点击事件(查看全部)
        viewAll(infoWindow, item)
      } else {
        document.getElementById('checkAll-btn').remove()
      }
    }
  })
  //设置文本标记
  var opts2 = {
    position: point, // 指定文本标注所在的地理位置
    offset: new BMap.Size(0, 25), // 设置文本偏移量
  }
  let content = `
          <div>
              <div class='labelSty' style="transform: translateX(-50%);position: absolute;left: 50%;color:#ffffff">
                ${item.locationOrCustomer}
              </div>
          </div>
        `
  // 创建文本标注对象
  var label = new BMap.Label(content, opts2)
  map.addOverlay(label)
}

// 查看档案
function viewProfile(infoWindow, item) {
  if (!infoWindow.isOpen()) {
    infoWindow.addEventListener('open', function(e) {
      var btn = document.getElementById('toFiles')
      btn.onclick = function() {
        _this.$router.push({
          path: '/elderlyRecords',
          query: {
            customerGlobalId: item.targetId,
            institutionNo: item.institutionNo,
          },
        })
      }
    })
  } else {
    var btn = document.getElementById('toFiles')
    btn.onclick = function() {
      _this.$router.push({
        path: '/elderlyRecords',
        query: {
          customerGlobalId: item.targetId,
          institutionNo: item.institutionNo,
        },
      })
    }
  }
}

// 点击次数
function numberClicks(infoWindow, data) {
  let text = `
    <div class='timeListData timesOne'>
        <div>${data.warnCategoryMark}：${data.warnTypeMark}</div>
        <div id='dateTimeList'></div>            
    </div>
    `
  if (!infoWindow.isOpen()) {
    infoWindow.addEventListener('open', function(e) {
      var btn = document.getElementById('num')
      btn.onclick = function() {
        if (document.getElementsByClassName('timesTwo').length !== 0) {
          document.getElementsByClassName('timesTwo')[0].remove()
        }
        document.getElementById('timeListData').innerHTML = text
        let textItem = ''
        data.deviceAlarms.forEach((itemItem) => {
          textItem =
            textItem +
            `
                <div>
                    ${itemItem.time}
                </div>
              `
        })
        document.getElementById('dateTimeList').innerHTML = textItem
      }
    })
  } else {
    var btn = document.getElementById('num')
    btn.onclick = function() {
      if (document.getElementsByClassName('timesTwo').length !== 0) {
        document.getElementsByClassName('timesTwo')[0].remove()
      }
      document.getElementById('timeListData').innerHTML = text
      let textItem = ''
      data.deviceAlarms.forEach((itemItem) => {
        textItem =
          textItem +
          `
              <div>
                  ${itemItem.time}
              </div>
            `
      })
      document.getElementById('dateTimeList').innerHTML = textItem
    }
  }
}

// 点击次数2
function numberClickss(infoWindow, data) {
  let text = `
      <div class='timeListData timesTwo'>
          <div>${data.warnCategoryMark}：${data.warnTypeMark}</div>
          <div id='dateTimeLists'>
          </div>            
      </div>
      `
  if (!infoWindow.isOpen()) {
    infoWindow.addEventListener('open', function(e) {
      var btn = document.getElementById('nums')
      btn.onclick = function() {
        if (document.getElementsByClassName('timesOne').length !== 0) {
          document.getElementsByClassName('timesOne')[0].remove()
        }
        document.getElementById('timeListDatas').innerHTML = text
        let textItem = ''
        data.deviceAlarms.forEach((itemItem) => {
          textItem =
            textItem +
            `
                  <div>
                      ${itemItem.time}
                  </div>
                `
        })
        document.getElementById('dateTimeLists').innerHTML = textItem
      }
    })
  } else {
    var btn = document.getElementById('nums')
    btn.onclick = function() {
      if (document.getElementsByClassName('timesOne').length !== 0) {
        document.getElementsByClassName('timesOne')[0].remove()
      }
      document.getElementById('timeListDatas').innerHTML = text
      let textItem = ''
      data.deviceAlarms.forEach((itemItem) => {
        textItem =
          textItem +
          `
                  <div>
                      ${itemItem.time}
                  </div>
                `
      })
      document.getElementById('dateTimeLists').innerHTML = textItem
    }
  }
}

// 查看全部点击事件
function viewAll(infoWindow, item) {
  if (!infoWindow.isOpen()) {
    infoWindow.addEventListener('open', function(e) {
      var btn = document.getElementById('checkAll-btn')
      btn.onclick = function() {
        _this.$router.push({
          path: '/elderlyRecords',
          query: {
            customerGlobalId: item.targetId,
            institutionNo: item.institutionNo,
          },
        })
      }
    })
  } else {
    var btn = document.getElementById('checkAll-btn')
    btn.onclick = function() {
      console.log('点击了查看全部1')
      _this.$router.push({
        path: '/elderlyRecords',
        query: {
          customerGlobalId: item.targetId,
          institutionNo: item.institutionNo,
        },
      })
    }
  }
}

// 弹框内容
function textBox(data) {
  let textData = ''
  if (data.jobOrders.length == 1) {
    let text = `
        <div class='textBox'>
            <div class='nameBox'>
                <div class='name'>
                    ${data.locationOrCustomer}
                </div>
                <div class='toFiles' id='toFiles'>
                    <div>查看档案</div>
                    <div>
                       <img src='${require('../static/toRight.png')}' />
                    </div>
                </div>
            </div>
            <div class='olderAddress'>
                <div class='olderAddress-img'>
                    <img src='${require('../static/pation.png')}' />
                </div>
                <div class='olderAddress-text'>
                    ${data.warnAddress}
                </div>
            </div>
            <div class='call-police'>
                <div class='call-police-item'>
                    <div class='call-police-name'>
                        <div class='name'>
                            ${data.jobOrders[0].deviceName}
                        </div>
                        <div class='num' id='num'>
                            <div class='numT'>${
                              data.jobOrders[0].deviceAlarms.length
                            }次</div>
                            <div class='numImg'>
                                <img src='${require('../static/toRight.png')}' />
                            </div>
                        </div>
                        <div id='timeListData' class='timeListData-main'></div>
                    </div>
                    <div class='call-police-times'>
                        <div class='call-police-text'>
                            <div>${data.jobOrders[0].warnCategoryMark}：</div>
                            <div>${data.jobOrders[0].warnTypeMark}</div>
                        </div>
                        <div class='call-police-time'>
                            ${data.jobOrders[0].warnTime}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `
    textData = text
  } else if (data.jobOrders.length == 2) {
    let text1 = `
    <div class='textBox'>
        <div class='nameBox'>
            <div class='name'>
                ${data.locationOrCustomer}
            </div>
            <div class='toFiles' id='toFiles'>
                <div>查看档案</div>
                <div>
                    <img src='${require('../static/toRight.png')}' />
                </div>
            </div>
        </div>
        <div class='olderAddress'>
            <div class='olderAddress-img'>
                <img src='${require('../static/pation.png')}' />
            </div>
            <div class='olderAddress-text'>
                ${data.warnAddress}
            </div>
        </div>
        <div class='call-police'>
            <div class='call-police-item'>
                <div class='call-police-name'>
                    <div class='name'>
                        ${data.jobOrders[0].deviceName}
                    </div>
                    <div class='num' id='num'>
                        <div class='numT'>${
                          data.jobOrders[0].deviceAlarms.length
                        }次</div>
                        <div class='numImg'>
                            <img src='${require('../static/toRight.png')}' />
                        </div>
                    </div>
                    <div id='timeListData' class='timeListData-main'></div>
                </div>
                <div class='call-police-times'>
                    <div class='call-police-text'>
                        <div>${data.jobOrders[0].warnCategoryMark}：</div>
                        <div>${data.jobOrders[0].warnTypeMark}</div>
                    </div>
                    <div class='call-police-time'>
                        ${data.jobOrders[0].warnTime}
                    </div>
                </div>
            </div>
            <div class='call-police-item'>
                <div class='call-police-name'>
                    <div class='name'>
                    ${data.jobOrders[1].deviceName}
                    </div>
                    <div class='num' id='nums'>
                        <div class='numT'>${
                          data.jobOrders[1].deviceAlarms.length
                        }次</div>
                        <div class='numImg'>
                            <img src='${require('../static/toRight.png')}' />
                        </div>
                    </div>
                    <div id='timeListDatas' class='timeListData-main'></div>
                </div>
                <div class='call-police-times'>
                    <div class='call-police-text'>
                        <div>${data.jobOrders[1].warnCategoryMark}：</div>
                        <div>${data.jobOrders[1].warnTypeMark}</div>
                    </div>
                    <div class='call-police-time'>
                        ${data.jobOrders[1].warnTime}
                    </div>
                </div>
            </div>
        </div>
    </div>
    `
    textData = text1
  } else {
    let text2 = `
    <div class='textBox'>
        <div class='nameBox'>
            <div class='name'>
                ${data.locationOrCustomer}
            </div>
            <div class='toFiles' id='toFiles'>
                <div>查看档案</div>
                <div>
                    <img src='${require('../static/toRight.png')}' />
                </div>
            </div>
        </div>
        <div class='olderAddress'>
            <div class='olderAddress-img'>
                <img src='${require('../static/pation.png')}' />
            </div>
            <div class='olderAddress-text'>
                ${data.warnAddress}
            </div>
        </div>
        <div class='call-police'>
            <div class='call-police-item'>
                <div class='call-police-name'>
                    <div class='name'>
                        ${data.jobOrders[0].deviceName}
                    </div>
                    <div class='num' id='num'>
                        <div class='numT'>${
                          data.jobOrders[0].deviceAlarms.length
                        }次</div>
                        <div class='numImg'>
                            <img src='${require('../static/toRight.png')}' />
                        </div>
                    </div>
                    <div id='timeListData' class='timeListData-main'></div>
                </div>
                <div class='call-police-times'>
                    <div class='call-police-text'>
                        <div>${data.jobOrders[0].warnCategoryMark}：</div>
                        <div>${data.jobOrders[0].warnTypeMark}</div>
                    </div>
                    <div class='call-police-time'>
                        ${data.jobOrders[0].warnTime}
                    </div>
                </div>
            </div>
            <div class='call-police-item'>
                <div class='call-police-name'>
                    <div class='name'>
                    ${data.jobOrders[1].deviceName}
                    </div>
                    <div class='num' id='nums'>
                        <div class='numT'>${
                          data.jobOrders[1].deviceAlarms.length
                        }次</div>
                        <div class='numImg'>
                            <img src='${require('../static/toRight.png')}' />
                        </div>
                    </div>
                    <div id='timeListDatas' class='timeListData-main'></div>
                </div>
                <div class='call-police-times'>
                    <div class='call-police-text'>
                        <div>${data.jobOrders[1].warnCategoryMark}：</div>
                        <div>${data.jobOrders[1].warnTypeMark}</div>
                    </div>
                    <div class='call-police-time'>
                        ${data.jobOrders[1].warnTime}
                    </div>
                </div>
            </div>
        </div>
        <div class='checkAll'>
            <div class='checkAll-btn' id='checkAll-btn'>查看全部${
              data.jobOrders.length
            }条</div>
        </div>
    </div>
    `
    textData = text2
  }
  return textData
}

export default {
  thisFun: (vm) => {
    _this = vm
  },
  bDmap,
}
